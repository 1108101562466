<template>
    <van-popup v-model="show" @click-overlay="close">
        <div class="tips_pop">
            <div class="tips_top">
                <div class="tips_title">{{ $t("lang.tips") }}</div>
                <div class="tips_close" @click="close">
                    <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                </div>
            </div>
            <div class="tips_box">
                <slot>
                    <div>{{ tips }}</div>
                </slot>
            </div>
            <div class="tips_footer" @click="confirm">
                {{ $t("lang.confirm") }}
            </div>
        </div>
    </van-popup>
</template>

<script>
export default {
    props: {
        tips: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        close() {
            this.show = false
        },
        open() {
            this.show = true
        },
        confirm() {
            this.close()
            this.$emit('confirm')
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.tips_pop {
    padding: .31rem;
    box-sizing: border-box;
    width: 6.9rem;
    background: $bg-popup-color;
    border-radius: .2rem;
    margin: 0 auto;
    padding-bottom: .06rem;

    .tips_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tips_title {
            font-weight: 400;
            font-size: .28rem;
            color: #fff;
            font-size: .24rem;
        }

        .tips_close {
            width: .42rem;
            height: .42rem;
            cursor: pointer;
            & > i{
                color: $font-iconfont-color;
            }
        }
    }

    .tips_box {
        margin: 0 auto;
        padding: .28rem 0;
        color: $font-theme-color;
        font-size: .24rem;
        max-height: 70vh;
        overflow: auto;
    }

    .tips_footer {
        width: 100%;
        box-sizing: border-box;
        height: .9rem;
        border-top: .01rem solid #999;
        line-height: .91rem;
        text-align: center;
        font-size: .3rem;
        font-weight: bold;
        color: #fff;
        cursor: pointer;
    }
}
</style>
