<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">

                <div class="Deposit">
                    <statusBar></statusBar>
                    <div class="header">
                        <div>{{ $t("deposit.Deposit") }}</div>
                        <div class="close">
                            <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                        </div>
                    </div>
                    <div class="Deposit-main">
                        <div class="first-Deposit">
                            <div class="btn" @click="toTutorial" v-if="depositType === 'TransferDeposit'">{{
                                $t("deposit.Tutorial") }}</div>
                            <div class="btn" v-else>
                                {{ $t("deposit.FirstDeposit") }}
                            </div>
                            <div v-if="showBonus">
                                <div v-if="((depositType === 'TransferDeposit' && usdtGratuityConditions == 'MAXIMUM') || (depositType === 'OnlineDeposit' && gratuityConditions == 'MAXIMUM')) && (userInfo && userInfo.firstRechargeState == 'No')"
                                    class="txt">
                                    <div
                                        v-if="rewardPercentage1 && rewardPercentage1 > rewardPercentage2 && rewardPercentage1 > rewardPercentage3">
                                        {{ rewardPercentageTxt1 }}</div>
                                    <div
                                        v-if="rewardPercentage2 && rewardPercentage2 > rewardPercentage3 && rewardPercentage2 >= rewardPercentage1">
                                        {{ rewardPercentageTxt2 }}</div>
                                    <div
                                        v-if="rewardPercentage3 && rewardPercentage3 >= rewardPercentage2 && rewardPercentage3 >= rewardPercentage1">
                                        {{ rewardPercentageTxt3 }}</div>
                                </div>
                                <div v-else-if="((depositType === 'TransferDeposit' && usdtGratuityConditions == 'MAXIMUM') || (depositType === 'OnlineDeposit' && gratuityConditions == 'MAXIMUM')) && (userInfo && userInfo.everyFirstRechargeState == 'No')"
                                    class="txt">
                                    <div v-if="rewardPercentage1 && rewardPercentage1 >= rewardPercentage2">
                                        {{ rewardPercentageTxt1 }}</div>
                                    <div v-if="rewardPercentage2 && rewardPercentage2 > rewardPercentage1">
                                        {{ rewardPercentageTxt2 }}</div>
                                </div>
                                <div v-else-if="((depositType === 'TransferDeposit' && usdtGratuityConditions == 'MAXIMUM') || (depositType === 'OnlineDeposit' && gratuityConditions == 'MAXIMUM'))"
                                    class="txt">
                                    <div v-if="rewardPercentage1">{{ rewardPercentageTxt1 }}</div>
                                </div>
                                <div v-else class="txt">
                                    <div v-if="rewardPercentage1">{{ rewardPercentageTxt1 }}</div>
                                    <div v-if="rewardPercentage2">{{ rewardPercentageTxt2 }}</div>
                                    <div v-if="rewardPercentage3">{{ rewardPercentageTxt3 }}</div>
                                </div>
                            </div>
                            <div class="detail" @click="$refs.depositRulesPopup.open()">{{ $t("deposit.Details") }}
                            </div>
                        </div>
                        <div class="tab">
                            <div class="tab__item" :class="{ 'act': item.value === depositType }"
                                v-for="(item) in depositTypes" :key="item.value" @click="changeDepositType(item)">
                                <i class="iconfont " :class="[item.icon]"></i>
                                <span>{{ item.name }}</span>
                            </div>
                        </div>
                        <div class="PaymentMethods-App" v-if="!isShowPayList">
                            <div class="DepositMethods-title">{{ $t("deposit.DepositMethods") }}</div>
                            <div class="DepositMethods-selected" @click="isShowPayList = true">
                                <div class="info">
                                    <img :src="payListImg" />
                                    <div>{{ payListName }}</div>
                                </div>
                                <div class="change">{{ $t("deposit.Change") }}</div>
                            </div>
                        </div>
                        <div class="PaymentMethods" :class="{ 'act': isShowPayList }">
                            <div class="DepositMethods-header">
                                <language :show="true" :showIcon="true"></language>
                                <div>
                                    <div>{{ $t("deposit.PaymentMethodsRregion") }}</div>
                                    <div>{{ payListName }}</div>
                                </div>
                            </div>
                            <div class="DepositMethods-box">
                                <div class="DepositMethods-title">{{ $t("deposit.DepositMethods") }}</div>
                                <div class="DepositMethods-list">
                                    <div class="DepositMethods-item" :class="{ 'act': item.id === payListId }"
                                        v-for="(item, index) in payList" :key="index" @click="handlePayListItemClick(item)">
                                        <img :src="item.imgUrl" />
                                        <div>{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="depositType === 'TransferDeposit'">
                            <div class="DepositContent" :class="{ 'hide': isShowPayList }">
                                <div class="amount_input">
                                    <div class="Recharge-Limt">{{ minRechargeAmt }} - {{ maxRechargeAmt }} U </div>
                                    <a-input v-model="usdtRechargeValue" type="number"
                                        :placeholder="$t('deposit.Amount') + '(U)'" @change="handleRechargeChange">
                                        <div v-if="rewardPercentage && showBonus" slot="suffix" class="extra">
                                            {{ $t("deposit.Extra") }} + U {{ rewardPercentage }}
                                        </div>
                                        <div v-else slot="suffix" class="noBonus">
                                            {{ $t("deposit.noBonus") }}
                                        </div>
                                    </a-input>
                                </div>
                                <div class="exchangeRate">
                                    <div class="exchangeRate_left">
                                        <div>{{ $t('deposit.ExchangeRate') }}</div>
                                        <div>
                                            <span>U1 : {{ CurrencyType }}{{ changeRate }}</span>
                                            <span class="iconfont icon-shuaxin" @click="getRate"></span>
                                        </div>
                                    </div>
                                    <div class="exchangeRate_right">
                                        <div>
                                            <div>{{ $t('deposit.Deposit') }}</div>
                                            <div>≈{{ rateMoney }}{{ currency }}</div>
                                        </div>
                                        <div v-if="rewardPercentage && showBonus">
                                            <div>{{ $t('deposit.Bonus') }}</div>
                                            <div>≈{{ rateBonus }}{{ currency }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="usdtList && usdtList.length" class="form_gap" style="margin-top: .01rem;">
                                    <div class="form_gap_item" :class="{ 'act': item.rechargeAmt == usdtRechargeValue }"
                                        v-for="(item, index) in usdtList" :key="index"
                                        @click="handleUsdtListItemClick(item)">
                                        <div class="num">U {{ item.rechargeAmt }}</div>
                                        <div v-if="showBonus">
                                            <div class="extra"
                                                v-if="usdtGratuityConditions == 'MAXIMUM' && (item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate))">
                                                <span v-if="userInfo && userInfo.firstRechargeState == 'No'">
                                                    +{{ item.firstHandselRate > item.handselRate ?
                                                        (item.firstHandselRate >
                                                            item.everyHandselRate ? (item.firstHandselRate || 0).toFixed(2) :
                                                            (item.everyHandselRate || 0).toFixed(2)) : (item.handselRate >
                                                                item.everyHandselRate ? (item.handselRate || 0).toFixed(2) :
                                                                (item.everyHandselRate || 0).toFixed(2)) }}
                                                </span>
                                                <span v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No'">
                                                    +{{ item.handselRate > item.everyHandselRate ? (item.handselRate ||
                                                        0).toFixed(2) : (item.everyHandselRate || 0).toFixed(2) }}
                                                </span>
                                                <span v-else>{{ (item.everyHandselRate || 0).toFixed(2) }}</span>
                                                <span>%</span>
                                            </div>
                                            <div class="extra"
                                                v-else-if="item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate)">
                                                <div
                                                    v-if="userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate">
                                                    +{{ item.handselRate + item.firstHandselRate + item.everyHandselRate
                                                    }}%
                                                </div>
                                                <div
                                                    v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate">
                                                    +{{ item.handselRate + item.everyHandselRate }}%
                                                </div>
                                                <div v-else>+{{ (item.everyHandselRate || 0).toFixed(2) }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a-button class="submit-btn" @click="handleUsdtRecharge">{{
                                    $t("deposit.Deposit") }}</a-button>
                                <a-button class="submit-btn toTransaction" @click="toTransaction">{{
                                    $t("Transaction.Transaction") }}</a-button>
                                <div class="showBonus">
                                    <div class="check-box" @click="showBonus = !showBonus">
                                        <i v-if="!showBonus" class="iconfont icon-xuanzeyixuanze checked"></i>
                                        <i v-else class="iconfont icon-a-xuanzeweixuanze"></i>
                                    </div>
                                    <div class="tips">{{ $t("deposit.showBonusTips") }}</div>
                                </div>
                                <div v-if="rulesDesc" class="rulesDesc">
                                    <div v-html="rulesDesc"></div>
                                </div>
                            </div>
                        </template>
                        <template v-if="depositType === 'OnlineDeposit'">
                            <div class="DepositContent" :class="{ 'hide': isShowPayList }">
                                <div v-if="BelongNation !== 'USA'" class="amount_input">
                                    <div class="Recharge-Limt">
                                        {{ minRechargeAmt }} - {{ maxRechargeAmt }} {{ currency }}
                                    </div>
                                    <a-input v-model="rechargeValue" type="number"
                                        :placeholder="$t('deposit.Amount') + '(' + currency + ')'"
                                        @change="handleRechargeChange">
                                        <div v-if="rewardPercentage && showBonus" slot="suffix" class="extra">
                                            {{ $t("deposit.Extra") }} + {{ CurrencyType }} {{ rewardPercentage }}
                                        </div>
                                        <div v-else slot="suffix" class="noBonus">
                                            {{ $t("deposit.noBonus") }}
                                        </div>
                                    </a-input>
                                </div>
                                <div class="form_gap">
                                    <div class="form_gap_item" :class="{ 'act': item.rechargeAmt == rechargeValue }"
                                        v-for="(item, index) in RechargeList" :key="index"
                                        @click="handleListItemClick(item)">
                                        <div class="num">{{ CurrencyType }} {{ item.rechargeAmt }}</div>
                                        <div v-if="showBonus">
                                            <div class="extra"
                                                v-if="gratuityConditions == 'MAXIMUM' && (item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate))">
                                                <span v-if="userInfo && userInfo.firstRechargeState == 'No'">
                                                    +{{ item.firstHandselRate > item.handselRate ?
                                                        (item.firstHandselRate >
                                                            item.everyHandselRate ? (item.firstHandselRate || 0).toFixed(2) :
                                                            (item.everyHandselRate || 0).toFixed(2)) : (item.handselRate >
                                                                item.everyHandselRate ? (item.handselRate || 0).toFixed(2) :
                                                                (item.everyHandselRate || 0).toFixed(2)) }}
                                                </span>
                                                <span v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No'">
                                                    +{{ item.handselRate > item.everyHandselRate ? (item.handselRate ||
                                                        0).toFixed(2) : (item.everyHandselRate || 0).toFixed(2) }}
                                                </span>
                                                <span v-else>{{ (item.everyHandselRate || 0).toFixed(2) }}</span>
                                                <span>%</span>
                                            </div>
                                            <div class="extra"
                                                v-else-if="item.everyHandselRate || (userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate) || (userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate)">
                                                <div
                                                    v-if="userInfo && userInfo.firstRechargeState == 'No' && item.firstHandselRate">
                                                    +{{ item.handselRate + item.firstHandselRate + item.everyHandselRate
                                                    }}%
                                                </div>
                                                <div
                                                    v-else-if="userInfo && userInfo.everyFirstRechargeState == 'No' && item.handselRate">
                                                    +{{ item.handselRate + item.everyHandselRate }}%
                                                </div>
                                                <div v-else>+{{ (item.everyHandselRate || 0).toFixed(2) }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a-button class="submit-btn" @click="handleRecharge">
                                    {{ $t("deposit.Deposit") }}
                                </a-button>
                                <div class="tips" style="lineHeight: .3rem;">{{ $t("deposit.DepositTips2") }}</div>
                                <div class="showBonus">
                                    <div class="check-box" @click="showBonus = !showBonus">
                                        <i v-if="!showBonus" class="iconfont icon-xuanzeyixuanze checked"></i>
                                        <i v-else class="iconfont icon-a-xuanzeweixuanze"></i>
                                    </div>
                                    <div class="tips">{{ $t("deposit.showBonusTips") }}</div>
                                </div>
                                <div v-if="rulesDesc" class="rulesDesc">
                                    <div v-html="rulesDesc"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </van-popup>
        <depositRulesPopup ref="depositRulesPopup"></depositRulesPopup>
        <payDetail ref="payDetail"></payDetail>
        <depositDetail ref="depositDetail" :details="depositDetails" @onContinue="onContinueDeposit"></depositDetail>
        <tipsPopup ref="depositTipsPop" :tips="$t('deposit.DepositTips2')"></tipsPopup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getRechargeList, createMemberRechargeOrder, getRate, createMemberUsdtRechargeOrder, getUsdtTutorials, getUsdtRechargeOrderByWaitPay } from '@/api/deposit'
import { getUserInfo } from '@/api/user'
import { Toast } from 'vant'
import depositRulesPopup from './depositRulesPopup.vue'
import depositDetail from './depositDetail.vue'
import payDetail from './payDetail.vue'
import statusBar from '@/components/statusBar'
import language from '@/components/language'
import tipsPopup from './tipsPopup.vue'
export default {
    name: 'depositPopup',
    components: { depositRulesPopup, depositDetail, statusBar, language, payDetail, tipsPopup },
    data() {
        return {
            depositType: 'OnlineDeposit',
            depositTypes: [{
                name: this.$t('deposit.OnlineDeposit'),
                key: 'OnlineDeposit',
                value: 'OnlineDeposit',
                icon: 'icon-shoujizhifu'
            }
                // {
                // 	name: 'Transfer Deposit',
                // 	key: 'TransferDeposit',
                // 	value: 'TransferDeposit',
                // 	icon: 'icon-yinxingqia'
                // }
            ],
            rechargeGiftType: '',
            RechargeList: [],
            gratuityConditions: '',
            rulesDesc: '',
            isShowPayList: false,
            totalPayList: [],
            payList: [],
            payListId: '',
            payListImg: '',
            payChannelType: '',
            payListName: '',
            rechargeValue: '',
            minRechargeAmt: 0,
            maxRechargeAmt: 0,
            rewardPercentage: 0,
            rewardPercentageAmount1: '',
            rewardPercentageAmount2: '',
            rewardPercentageAmount3: '',
            rewardPercentageTxt1: '',
            rewardPercentageTxt2: '',
            rewardPercentageTxt3: '',
            rewardPercentage1: '',
            rewardPercentage2: '',
            rewardPercentage3: '',
            showBonus: true,
            userInfo: {},
            progress: 0,
            loading: false,
            canCallHandleRecharge: true,

            changeRate: 0,
            usdtRechargeValue: '',
            usdtRechargeGiftType: '',
            usdtGratuityConditions: '',
            usdtList: [],
            depositDetails: {}
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        currency() {
            const currency = this.$store.getters.sysConfig?.currency
            return currency || ''
        },
        rateMoney() {
            return (Number(this.changeRate || 0) * Number(this.usdtRechargeValue || 0)).toFixed(2)
        },
        rateBonus() {
            return (Number(this.changeRate || 0) * Number(this.rewardPercentage || 0)).toFixed(2)
        },
        show: {
            get() {
                return this.$store.getters.isShowDepositPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.isShowPayList = false
            this.$store.dispatch('user/setIsShowDepositPopup', false)
        },
        open() {
            this.$nextTick(() => {
                this.$refs.depositTipsPop.open()
            })
            getUserInfo().then(res1 => {
                if (res1.code) return
                this.userInfo = res1.result
                const grandRechargeNumber = Number(this.userInfo.grandRechargeNumber) + 1

                getRechargeList().then(res => {
                    if (res.code) {
                        Toast(res.message)
                        return
                    }
                    const D = res.result

                    this.RechargeList = []
                    this.rechargeGiftType = D.rechargeGiftType
                    if (this.rechargeGiftType === 'firstRecharge') {
                        this.RechargeList = D.firstRechargeDetailVoList
                        this.gratuityConditions = D.gratuityConditions
                    }
                    if (this.rechargeGiftType === 'repeatRecharge') {
                        this.RechargeList = D.everyRepeatRechargeDetailVoList
                        if (D && D.repeatRechargeNumberDetailVoList.length) {
                            if (D.repeatRechargeNumberDetailVoList.some(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))) {
                                this.RechargeList = D.repeatRechargeNumberDetailVoList.filter(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))[0].repeatRechargeDetailVoList
                            }
                        }
                    }

                    this.usdtList = []
                    this.usdtRechargeGiftType = D.usdtRechargeGiftType
                    if (this.usdtRechargeGiftType === 'firstRecharge') {
                        this.usdtList = D.usdtFirstRechargeDetailVoList
                        this.usdtGratuityConditions = D.usdtGratuityConditions
                    }
                    if (this.usdtRechargeGiftType === 'repeatRecharge') {
                        this.usdtList = D.usdtEveryRepeatRechargeDetailVoList
                        if (D && D.usdtRepeatRechargeNumberDetailVoList.length) {
                            if (D.usdtRepeatRechargeNumberDetailVoList.some(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))) {
                                this.usdtList = D.usdtRepeatRechargeNumberDetailVoList.filter(x => Number(x.rechargeNumber) === Number(grandRechargeNumber))[0].repeatRechargeDetailVoList
                            }
                        }
                    }

                    this.totalPayList = D.payList.filter(x => x.enableRechargeType === 1).sort((a, b) => b.sort - a.sort)
                    this.changeDepositType(this.depositTypes[0])
                    this.rulesDesc = D.explainDec
                    this.initDepositTypes()
                    this.getRewardPercentage()
                })
            })
        },
        initDepositTypes() {
            const payList = this.totalPayList.filter(x => x.payChannelType.indexOf('USDT') !== -1)
            if (payList && payList.length) {
                this.depositTypes = [{
                    name: this.$t('deposit.OnlineDeposit'),
                    key: 'OnlineDeposit',
                    value: 'OnlineDeposit',
                    icon: 'icon-shoujizhifu'
                },
                {
                    name: this.$t('deposit.TransferDeposit'),
                    key: 'TransferDeposit',
                    value: 'TransferDeposit',
                    icon: 'icon-USDT'
                }]
            }
        },
        changeDepositType(item) {
            this.depositType = item.value
            if (this.depositType === 'TransferDeposit') {
                this.getRate()
                getUsdtRechargeOrderByWaitPay().then((res) => {
                    if (res.code) return
                    const D = res.result
                    this.onShowDetail(D)
                })
                this.payList = this.totalPayList.filter(x => x.payChannelType.indexOf('USDT') !== -1)
            } else {
                this.payList = this.totalPayList.filter(x => x.payChannelType.indexOf('USDT') === -1)
            }
            if (this.payList && this.payList.length) {
                this.payListId = this.payList[0].id
                this.payChannelType = this.payList[0].payChannelType
                this.payListImg = this.payList[0].imgUrl
                this.payListName = this.payList[0].name
                this.payList.forEach(x => {
                    if (x.id === this.payListId) {
                        this.minRechargeAmt = x.minRechargeAmt || 0
                        this.maxRechargeAmt = x.maxRechargeAmt || 0
                    }
                })
            } else {
                this.minRechargeAmt = 0
                this.maxRechargeAmt = 0
                this.payListId = ''
                this.payChannelType = ''
                this.payChannelType = ''
                this.payListName = ''
            }
            this.getRewardPercentage()
        },
        getRate() {
            getRate().then(res => {
                if (res.code) return
                this.changeRate = res.result
            })
        },
        getRewardPercentage() {
            let rechargeValue = 0
            let list = []

            if (this.depositType === 'OnlineDeposit') {
                rechargeValue = this.rechargeValue
                list = this.RechargeList || []
            }
            if (this.depositType === 'TransferDeposit') {
                rechargeValue = this.usdtRechargeValue
                list = this.usdtList || []
            }
            if (Number(rechargeValue) < Number(this.rewardPercentageAmount1)) {
                this.rewardPercentageTxt1 = ''
                this.rewardPercentage1 = ''
            }
            if (Number(rechargeValue) < Number(this.rewardPercentageAmount2)) {
                this.rewardPercentageTxt2 = ''
                this.rewardPercentage2 = ''
            }
            if (Number(rechargeValue) < Number(this.rewardPercentageAmount3)) {
                this.rewardPercentageTxt3 = ''
                this.rewardPercentage3 = ''
            }
            if (!list.length) {
                this.rewardPercentage = 0
                return
            }
            if (rechargeValue < list[0].rechargeAmt) {
                this.rewardPercentage = 0
                return
            }
            if (!this.rewardPercentage1 && !this.rewardPercentage2 && !this.rewardPercentage3) {
                this.rewardPercentage = 0
            }
            for (let i = 0; i < list.length; i++) {
                if (Number(rechargeValue) >= Number(list[i].rechargeAmt)) {
                    if ((i + 1) < list.length) {
                        if (Number(rechargeValue) <= Number(list[i + 1].rechargeAmt)) {
                            let hasEveryReward = 0
                            let hasReward = 0
                            let hasFirstReward = 0
                            let j = Number(i)
                            for (j; j >= 0; j--) {
                                if (!hasEveryReward && list[j].everyHandselRate) {
                                    hasEveryReward = list[j].everyHandselRate
                                    this.getRP(list[j], 'EveryReward')
                                }
                                if (!hasReward && list[j].handselRate) {
                                    hasReward = list[j].handselRate
                                    this.getRP(list[j], 'Reward')
                                }
                                if (!hasFirstReward && list[j].firstHandselRate) {
                                    hasFirstReward = list[j].firstHandselRate
                                    this.getRP(list[j], 'FirstReward')
                                }
                            }
                        }
                    } else {
                        let hasEveryReward = 0
                        let hasReward = 0
                        let hasFirstReward = 0
                        let j = Number(i)
                        for (j; j >= 0; j--) {
                            if (!hasEveryReward && list[j].everyHandselRate) {
                                hasEveryReward = list[j].everyHandselRate
                                this.getRP(list[j], 'EveryReward')
                            }
                            if (!hasReward && list[j].handselRate) {
                                hasReward = list[j].handselRate
                                this.getRP(list[j], 'Reward')
                            }
                            if (!hasFirstReward && list[j].firstHandselRate) {
                                hasFirstReward = list[j].firstHandselRate
                                this.getRP(list[j], 'FirstReward')
                            }
                        }
                    }
                }
            }
        },
        getRP(x, type) {
            let rechargeValue = 0
            let gratuityConditions = 'TOTAL'
            if (this.depositType === 'OnlineDeposit') {
                rechargeValue = this.rechargeValue
                gratuityConditions = this.gratuityConditions
            }
            if (this.depositType === 'TransferDeposit') {
                rechargeValue = this.usdtRechargeValue
                gratuityConditions = this.usdtGratuityConditions
            }
            this.rewardPercentage = ''
            if (this.userInfo && this.userInfo.firstRechargeState === 'No') {
                if (x.everyHandselRate && type === 'EveryReward') {
                    this.rewardPercentageTxt1 = this.$t('deposit.rewardPercentageTxt1_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt1_2') + x.everyHandselRate + this.$t('deposit.rewardPercentageTxt1_3')
                    this.rewardPercentage1 = x.everyHandselRate
                    this.rewardPercentageAmount1 = x.rechargeAmt
                }
                if (x.handselRate && type === 'Reward') {
                    this.rewardPercentageTxt2 = this.$t('deposit.rewardPercentageTxt2_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt2_2') + x.handselRate + this.$t('deposit.rewardPercentageTxt2_3')
                    this.rewardPercentage2 = x.handselRate
                    this.rewardPercentageAmount2 = x.rechargeAmt
                }
                if (x.firstHandselRate && type === 'FirstReward') {
                    this.rewardPercentageTxt3 = this.$t('deposit.rewardPercentageTxt3_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt3_2') + x.firstHandselRate + this.$t('deposit.rewardPercentageTxt3_3')
                    this.rewardPercentage3 = x.firstHandselRate
                    this.rewardPercentageAmount3 = x.rechargeAmt
                }
            } else if (this.userInfo && this.userInfo.everyFirstRechargeState === 'No') {
                if (x.everyHandselRate && type === 'EveryReward') {
                    this.rewardPercentageTxt1 = this.$t('deposit.rewardPercentageTxt1_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt1_2') + x.everyHandselRate + this.$t('deposit.rewardPercentageTxt1_3')
                    this.rewardPercentage1 = x.everyHandselRate
                    this.rewardPercentageAmount1 = x.rechargeAmt
                }
                if (x.handselRate && type === 'Reward') {
                    this.rewardPercentageTxt2 = this.$t('deposit.rewardPercentageTxt2_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt2_2') + x.handselRate + this.$t('deposit.rewardPercentageTxt2_3')
                    this.rewardPercentage2 = x.handselRate
                    this.rewardPercentageAmount2 = x.rechargeAmt
                }
            } else {
                if (x.everyHandselRate && type === 'EveryReward') {
                    this.rewardPercentageTxt1 = this.$t('deposit.rewardPercentageTxt1_1') + x.rechargeAmt + this.$t('deposit.rewardPercentageTxt1_2') + x.everyHandselRate + this.$t('deposit.rewardPercentageTxt1_3')
                    this.rewardPercentage1 = x.everyHandselRate
                    this.rewardPercentageAmount1 = x.rechargeAmt
                }
            }
            if (gratuityConditions === 'MAXIMUM') {
                if (this.userInfo && this.userInfo.firstRechargeState === 'No') {
                    if (Number(x.firstHandselRate) >= Number(x.handselRate) && Number(x.firstHandselRate) >= Number(x.everyHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.firstHandselRate || 0))
                    }
                    if (Number(x.handselRate) >= Number(x.firstHandselRate) && Number(x.handselRate) >= Number(x.everyHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.handselRate || 0))
                    }
                    if (Number(x.everyHandselRate) >= Number(x.handselRate) && Number(x.everyHandselRate) >= Number(x.firstHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.everyHandselRate || 0))
                    }
                } else if (this.userInfo && this.userInfo.everyFirstRechargeState === 'No') {
                    if (Number(x.handselRate) >= Number(x.everyHandselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.handselRate || 0))
                    }
                    if (Number(x.everyHandselRate) >= Number(x.handselRate)) {
                        this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.everyHandselRate || 0))
                    }
                } else {
                    this.rewardPercentage = parseInt(Number(rechargeValue || 0) / 100 * Number(x.everyHandselRate || 0))
                }
            } else {
                if (this.rewardPercentage1) {
                    this.rewardPercentage = Number(this.rewardPercentage) + parseInt(Number(rechargeValue || 0) / 100 * Number(this.rewardPercentage1 || 0))
                }
                if (this.rewardPercentage2) {
                    this.rewardPercentage = Number(this.rewardPercentage) + parseInt(Number(rechargeValue || 0) / 100 * Number(this.rewardPercentage2 || 0))
                }
                if (this.rewardPercentage3) {
                    this.rewardPercentage = Number(this.rewardPercentage) + parseInt(Number(rechargeValue || 0) / 100 * Number(this.rewardPercentage3 || 0))
                }
            }
        },
        handlePayListItemClick(item) {
            this.isShowPayList = false
            this.payListId = item.id
            this.payChannelType = item.payChannelType
            this.payListImg = item.imgUrl
            this.payListName = item.name
            this.minRechargeAmt = item.minRechargeAmt || 0
            this.maxRechargeAmt = item.maxRechargeAmt || 0
        },
        handleRechargeChange() {
            this.getRewardPercentage()
        },
        handleListItemClick(item) {
            this.rechargeValue = item.rechargeAmt
            this.getRewardPercentage()
        },
        handleUsdtListItemClick(item) {
            this.usdtRechargeValue = item.rechargeAmt
            this.getRewardPercentage()
        },
        handleRecharge() {
            if (!this.payListId) return
            // if (this.canCallHandleRecharge) {
            //     this.$refs.payDetail.setMsg('123')
            //     // this.$refs.payDetail.open()
            //     return
            // }
            if (this.canCallHandleRecharge) {
                this.canCallHandleRecharge = false
                if (!this.rechargeValue) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.PleaseEnterDeposit'))
                    return
                }
                if (this.minRechargeAmt && Number(this.rechargeValue || 0) < Number(this.minRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMinRechargeAmtTips'))
                    return
                }
                if (this.maxRechargeAmt && Number(this.rechargeValue || 0) > Number(this.maxRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMaxRechargeAmtTips'))
                    return
                }
                const userAgent = navigator.userAgent
                const params = {
                    payChannelId: this.payListId,
                    orderAmt: this.rechargeValue,
                    yesNoType: this.showBonus ? 'Yes' : 'No',
                    userAgent
                }
                createMemberRechargeOrder(params, { custom: { toast: false } })
                    .then(res => {
                        if (res.code === 0 && !this.canCallHandleRecharge) {
                            if (this.payChannelType !== 'RuSuperPay') {
                                this.close()
                            }

                            this.canCallHandleRecharge = true
                            const url = res.result.payUrl
                            if (this.payChannelType === 'SupefinaPay') {
                                window.location.href = url
                            } else if (this.payChannelType === 'TurCodePay') {
                                window.location.href = url
                            } else if (this.payChannelType === 'RuSuperPay') {
                                this.$refs.payDetail.setMsg(url)
                            } else {
                                const encodedUrl = encodeURIComponent(url)
                                this.onNavTo({ url: '/depositWv', type: 'get', params: { url: encodedUrl } })
                            }
                        }
                        if (res.code !== 0) {
                            this.canCallHandleRecharge = true
                        }
                    }).catch(() => {
                        this.canCallHandleRecharge = true
                    }).finally(() => {
                        this.canCallHandleRecharge = true
                    })
            }
        },
        handleUsdtRecharge() {
            if (!this.payListId) return
            if (this.canCallHandleRecharge) {
                this.canCallHandleRecharge = false
                if (!this.usdtRechargeValue) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.PleaseEnterDeposit'))
                    return
                }
                if (this.minRechargeAmt && Number(this.usdtRechargeValue || 0) < Number(this.minRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMinRechargeAmtTips'))
                    return
                }
                if (this.maxRechargeAmt && Number(this.usdtRechargeValue || 0) > Number(this.maxRechargeAmt || 0)) {
                    this.canCallHandleRecharge = true
                    Toast(this.$t('deposit.isMaxRechargeAmtTips'))
                    return
                }
                const userAgent = navigator.userAgent
                const params = {
                    payChannelId: this.payListId,
                    usdtOrderAmt: this.usdtRechargeValue,
                    yesNoType: this.showBonus ? 'Yes' : 'No',
                    userAgent
                }
                createMemberUsdtRechargeOrder(params)
                    .then(res => {
                        if (res.code === 0 && !this.canCallHandleRecharge) {
                            this.canCallHandleRecharge = true
                            this.onShowDetail(res.result)
                        }
                        if (res.code !== 0) {
                            Toast(res.message)
                            this.canCallHandleRecharge = true
                        }
                    }).catch(() => {
                        this.canCallHandleRecharge = true
                    }).finally(() => {
                        this.canCallHandleRecharge = true
                    })
            }
        },
        onShowDetail(item) {
            this.$nextTick(() => {
                this.depositDetails = item
                this.$refs.depositDetail.open()
            })
        },
        onContinueDeposit() {
            this.depositType = 'TransferDeposit'
        },
        toTutorial() {
            if (this.depositType === 'TransferDeposit') {
                getUsdtTutorials().then((res) => {
                    if (!res) return
                    if (res.code) return
                    const D = res.result
                    const obj = { eventName: this.$t('deposit.Tutorial'), eventContent: D }
                    const params = encodeURIComponent(JSON.stringify(obj))
                    const timestamp = new Date().getTime()
                    this.onNavTo({ url: 'PromotionCustom', type: 'post', params: { timestamp, params } })
                    this.close()
                })
            }
        },
        toTransaction() {
            this.$store.dispatch('user/setIsShowTransactionPopup', true)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            border-radius: .2rem;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 686px;
            position: relative;
            padding-left: 300px;

            .tab {
                height: .76rem;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                z-index: 3;
                cursor: pointer;
                border-bottom: .01rem solid #e5e7eb;
                margin-top: .1rem;

                &__item {
                    text-align: center;
                    color: #B1BAD3;
                    font-size: .24rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: .3rem;
                    margin-right: .48rem;
                    position: relative;
                    text-align: left;
                    height: 100%;

                    &>i {
                        margin-right: .15rem;
                        font-size: .34rem;
                    }

                    &.act {
                        color: #ff7300;

                        &:after {
                            content: '';
                            width: 100%;
                            height: .04rem;
                            background-color: #ff7300;
                            position: absolute;
                            bottom: -.02rem;
                            left: 0;
                        }
                    }
                }
            }

            .PaymentMethods {
                width: 300px;
                height: 100%;
                background: #191b21;
                padding: 20px 10px;
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;

                .DepositMethods-header {
                    display: flex;
                    align-items: center;
                    text-align: left;
                    padding: 8px 0;

                    &>img {
                        width: 30px;
                        height: 30px;
                        box-sizing: border-box;
                        margin-right: .14rem;
                    }

                    &>div {
                        &>div:first-child {
                            color: #afc2e4;
                            font-size: .2rem;
                            line-height: .26rem;
                        }

                        &>div:last-child {
                            color: #fff;
                            font-size: .25rem;
                            font-weight: 700;
                            line-height: .3rem;
                        }
                    }
                }

                .DepositMethods-box {
                    padding-top: 2px;

                    .DepositMethods-title {
                        text-align: left;
                        color: #fff;
                        font-size: .25rem;
                        font-weight: bold;
                    }

                    .DepositMethods-list {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .DepositMethods-item {
                            width: calc(50% - 5px);
                            border-radius: 12px;
                            background: #1d2027;
                            border-style: solid;
                            border-width: 1px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            height: 88px;
                            margin-top: 10px;
                            cursor: pointer;

                            &.act {
                                border-color: #286aff;
                                background: #2a2f39;
                            }

                            &>img {
                                height: auto;
                                min-height: 38px;
                                width: 60px;
                                box-sizing: border-box;
                                background-color: #fff;
                                border-radius: .05rem;
                            }

                            &>div {
                                color: #fff;
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .Deposit {
                width: 500px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .header {
                    display: flex;
                    height: 1rem;
                    padding-left: .46rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: .1rem;

                    &>div:first-child {
                        font-size: .32rem;
                        font-weight: bold;
                        color: #fff;
                    }

                    .close {
                        color: #516382;
                        font-size: .36rem;
                        font-weight: bold;
                        height: 1rem;
                        width: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }

                .Deposit-main {
                    padding: 0 20px;
                    padding-bottom: 30px;
                    width: 100%;
                    flex: 1;
                    overflow: auto;
                    background: #1d2027;

                    .DepositContent {
                        position: relative;
                        z-index: 2;
                    }

                    .first-Deposit {
                        border-radius: 12px;
                        width: 100%;
                        background-image: url('@/assets/deposit/new_pay_banner_pc.jpg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100px;
                        position: relative;
                        padding-left: 25px;
                        padding-bottom: 9px;
                        padding-top: 9px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        overflow: hidden;

                        .btn {
                            color: #8f310f;
                            background: linear-gradient(180deg, #fff7bc, #ffe766 44.79%, #ffb932 53.65%, #ffcf54 94.9%, #ffebb9 99.58%);
                            font-weight: 700;
                            border-radius: 100px;
                            cursor: auto;
                            padding: 5px 20px;
                            width: max-content;
                            font-size: 14px;
                            margin-bottom: 3px;
                            cursor: pointer;
                        }

                        .txt {
                            color: #fff;
                            font-size: 14px;
                            text-align: left;
                            font-weight: bold;
                            line-height: 17px;

                            .val {
                                color: #f3df00;
                            }
                        }

                        .detail {
                            color: #fff;
                            background: rgba(0, 0, 0, .5);
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            border-top-left-radius: 6px;
                            padding: 3px 15px;
                            cursor: pointer;
                        }
                    }

                    .PaymentMethods-App {
                        display: none;
                        margin-top: .16rem;

                        .DepositMethods-title {
                            text-align: left;
                            color: #fff;
                            font-size: .24rem;
                            font-weight: bold;
                        }

                        .DepositMethods-selected {
                            width: 100%;
                            height: .9rem;
                            border-radius: .12rem;
                            background: #1d2027;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: .24rem;
                            margin-top: .1rem;

                            .info {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: .28rem;
                                color: #fff;

                                &>img {
                                    height: auto;
                                    min-height: .38rem;
                                    width: .62rem;
                                    box-sizing: border-box;
                                    background-color: #fff;
                                    border-radius: .05rem;
                                    margin-right: .15rem;
                                }
                            }

                            .change {
                                color: #ff7300;
                                font-weight: 500;
                                font-size: .24rem;
                            }
                        }
                    }

                    .amount_input {
                        .Recharge-Limt {
                            color: #afc2e4;
                            font-size: 14px;
                            margin-top: 10px;
                            text-align: left;
                        }

                        ::v-deep input::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                        }

                        ::v-deep input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }

                        ::v-deep input[type='number'] {
                            -moz-appearance: textfield;
                        }

                        ::v-deep .ant-input {
                            background-color: #000 !important;
                            border-radius: 0.14rem;
                            font-size: 0.22rem;
                            height: 0.85rem;
                            padding: 0px 0.24rem !important;
                            color: #e3e3e3;
                            border: .02rem solid #383e4b !important;
                            padding-right: 2.8rem !important;

                            &:focus {
                                background-color: transparent;
                                border-color: #286aff !important;
                            }

                            &::-webkit-input-placeholder {
                                /* WebKit browsers */
                                font-size: .22rem;
                                color: #516382;
                                opacity: .7;
                                font-weight: 100;
                            }

                            &:-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 */
                                font-size: .22rem;
                                color: #516382;
                            }

                            &::-moz-placeholder {
                                /* Mozilla Firefox 19+ */
                                font-size: .22rem;
                                color: #516382;
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10+ */
                                font-size: .22rem;
                                color: #516382;
                            }

                        }

                        ::v-deep .ant-input-suffix {
                            .extra {
                                color: #ff7300;
                                padding-right: .24rem;
                            }

                            .noBonus {
                                color: #f3df00;
                                padding-right: .24rem;
                            }
                        }
                    }

                    .exchangeRate {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding: .08rem 0;

                        .exchangeRate_left {
                            color: #666;
                            font-size: .22rem;
                            line-height: .3rem;
                            text-align: left;

                            &>div:last-child {
                                color: #fff;
                                display: flex;
                                align-items: center;
                                font-size: .22rem;
                                text-align: left;

                                &>span:last-child {
                                    color: #D1AE52;
                                    font-size: .3rem;
                                    margin-left: .12rem;
                                }
                            }
                        }

                        .exchangeRate_right {
                            text-align: right;
                            color: #FFAA09;
                            font-weight: 700;
                            font-size: .24rem;
                        }
                    }

                    .form_gap {
                        display: flex;
                        gap: 9px;
                        cursor: pointer;
                        position: relative;
                        margin: .18rem 0;
                        flex-wrap: wrap;

                        .form_gap_item {
                            width: calc(33.33333% - 6px);
                            border-radius: .12rem;
                            box-sizing: border-box;
                            font-size: .24rem;
                            border: .01rem solid #383e4b;
                            padding: .1rem 0;
                            padding-top: .14rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            height: 1rem;

                            &.act {
                                border-color: #ff7300;
                                background: linear-gradient(180deg, #1d2027 -1.09%, #3e2020 97.83%);
                            }

                            .num {
                                color: #fff;
                                font-weight: bold;
                                font-size: .3rem;
                                line-height: .32rem;
                            }

                            .extra {
                                font-size: .18rem;
                                color: #ff7300;
                                line-height: .24rem;
                            }
                        }
                    }

                    .submit-btn {
                        width: 100%;
                        font-size: .24rem;
                        height: .65rem;
                        text-align: center;
                        line-height: .65rem;
                        background-color: #ff7300;
                        color: #fff;
                        font-weight: bold;
                        border-radius: .14rem;
                        cursor: pointer;

                        &.ant-btn {
                            border-color: transparent;
                        }

                        &.toTransaction {
                            background: #286aff;
                            margin-top: .2rem;
                            margin-bottom: .1rem;
                        }
                    }

                    .showBonus {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding: .06rem 0;

                        .check-box {
                            margin-bottom: .01rem;
                            margin-right: .1rem;

                            &>i {
                                font-size: .3rem;
                                color: #286aff;
                            }

                            &>i.checked {
                                color: #286aff;
                            }
                        }
                    }

                    .tips {
                        color: #afc2e4;
                        font-size: .22rem;
                        line-height: .38rem;
                        margin-top: 0.03rem;
                        text-align: left;
                    }
                }

                .rulesDesc {
                    color: #fff;
                    background: rgba(40, 106, 255, .2);
                    border-radius: 12px;
                    width: 100%;
                    padding: 10px;
                    box-sizing: border-box;
                    text-align: left;

                    ::v-deep table {
                        width: 100% !important;
                        border-collapse: collapse;
                        /* 使得边框合并为单一边框 */
                    }

                    ::v-deep table {
                        border: 1px solid #888;
                        /* 设置表格、表头、单元格的边框 */
                    }

                    ::v-deep th {
                        border: 1px solid #888;
                        /* 设置表格、表头、单元格的边框 */
                    }

                    ::v-deep td {
                        border: 1px solid #888;
                        /* 设置表格、表头、单元格的边框 */
                    }

                    ::v-deep td {
                        // padding: 8upx 15upx ;
                    }
                }
            }
        }
    }

    &.mobile {
        // width: 100% !important;
        // height: 100% !important;

        .van-popup {
            &.van-popup--center {
                top: 0;
                left: 0;
                transform: translate3d(0, 0, 0);
                width: 100% !important;
                height: 100% !important;
            }
        }

        .content {
            width: 100% !important;
            height: 100% !important;
            padding-left: 0 !important;

            .Deposit {
                width: 100% !important;

                .header {
                    padding-left: .24rem !important;
                }

                .Deposit-main {
                    padding: 0 .24rem !important;
                    background: #15161c !important;
                    position: relative;
                    z-index: 2;

                    .DepositContent {
                        &.hide {
                            display: none;
                            z-index: 1;
                        }
                    }

                    .first-Deposit {
                        height: 1.52rem !important;
                        padding-left: .45rem !important;

                        .btn {
                            padding: .045rem .45rem;
                        }

                        .txt {
                            font-size: .22rem;
                        }
                    }

                    .PaymentMethods-App {
                        display: block;
                    }
                }
            }
        }

        .PaymentMethods {
            display: none;
            background: #1d2027;
            position: relative;
            left: auto;
            top: 1.52rem !important;
            width: 100% !important;

            &.act {
                display: block;
                z-index: 3;
            }
        }
    }
}
</style>
