export default {
    lang: {
        LoadMore: 'Загрузить больше',
        AllLoaded: 'Все загружено',
        ChangeLanguage: 'Сменить язык',
        ChangePassword: 'Сменить пароль',
        copySuccess: 'Копирование успешно',
        copyError: 'Ошибка копирования',
        CopyEmptyTips: 'Скопированное содержимое не может быть пустым',
        empty: 'Здесь пусто',
        tips: 'Советы',
        cancel: 'Отмена',
        confirm: 'Подтвердить',
        more: 'Больше',
        isCloseGame: 'Вы хотите закрыть игру?',
        back: 'Назад',
        Claim: 'Получить',
        Loading: 'Загрузка',
        Play: 'Играть',
        Download: 'Скачать',
        NoData: 'Упс! Данных пока нет!',
        NoMore: 'Больше нет',
        CurrentlyPage: 'В настоящее время на этой странице',
        keywordPlaceholder: 'Введите название игры для поиска!'
    },
    tabbar: {
        Lobby: 'Главная',
        Earn: 'Поделиться',
        Game: 'Игра',
        Event: 'БОНУСЫ',
        Promotion: 'БОНУСЫ',
        Account: 'Профиль',
        Recharge: 'Пополнить',
        serve: 'Служба',
        Deposit: 'Депозит',
        Affiliate: 'Партнёр'
    },
    home: {
        Download: 'Скачать',
        DownloadTips: 'Скачайте приложение и получите ',
        Search: 'Поиск',
        Popular: 'Популярные',
        Recent: 'Недавние',
        Favorite: 'Избранное',
        Providers: 'ПРОВАЙДЕРЫ',
        CollectionSucc: 'Сбор успешно',
        CollectionCanceled: 'Сбор отменён',
        MORE: 'БОЛЬШЕ',
        Top: 'Топ',
        greatBonus: 'Отличный бонус за депозит',
        pullRefresh: 'Потяните вниз, чтобы обновить...',
        releaseRefresh: 'Отпустите, чтобы обновить...',
        loading: 'загрузка...'
    },
    login: {
        Login: 'Вход',
        SignIn: 'Войти',
        SignUp: 'Регистрация',
        RememberMe: 'Запомнить меня',
        ForgetPassword: 'Забыли пароль?',
        LoginTips: 'Чтобы посетить этот сайт, убедитесь, что вам больше 18 лет и вы согласны?',
        TermsOfService: 'Условия обслуживания',
        jumpRegister: 'Перейти к регистрации',
        JumpLogin: 'Перейти к входу',
        noAccountTips: 'У вас нет аккаунта?',
        hasAccountTips: 'У вас есть аккаунт?',
        Email: 'Электронная почта',
        VerificationCode: 'Код подтверждения',
        Send: 'Отправить',
        RestPassword: 'Сбросить пароль',
        passwordTips: 'Пароль (6-16)',
        passwordTips2: 'Введите пароль ещё раз',
        password: 'Пароль',
        phone: 'Номер телефона',
        ReferralCode: 'Реферальный код',
        CreateAccount: 'Создать аккаунт',
        PleaseEnterYourPhoneNumber: 'Пожалуйста, введите номер телефона',
        PleaseEnterYourPassword: 'Пожалуйста, введите ваш пароль',
        ThePasswordMustContain6To16Characters: 'Пароль должен содержать от 6 до 16 символов',
        InconsistentPassword: 'Пароли не совпадают',
        PleaseEnterInvitationCode: 'Пожалуйста, введите реферальный код',
        PleaseEnterEmail: 'Пожалуйста, введите вашу электронную почту',
        PleaseEnterEmailFormat: 'Пожалуйста, введите правильный формат электронной почты',
        day: 'день',
        also: 'также',
        downTime: 'Дата с момента запуска',
        changePwd: 'Сменить пароль',
        RechargeNow: 'Пополнить сейчас',
        PlayGame: 'Регистрация успешна! Играйте в игру~',
        serviceTips: 'Пожалуйста, сначала согласитесь с условиями обслуживания',
        SuccessfullyClaimed: 'Успешно получено',
        SuccessfullyClaimedTips1: '',
        SuccessfullyClaimedTips2: ' бонус будет зачислен на счёт',

        Account: 'Аккаунт',
        PleaseEnterAccount: 'Пожалуйста, введите аккаунт',
        AccountFormat: 'Ошибка формата аккаунта, 6-16 символов, поддерживает английские буквы/цифры',
        PhoneFormat: 'Формат введённого вами номера телефона неверен',
        PleaseEnterPassword: 'Пожалуйста, введите пароль',
        PasswordFormat: '8-16 символов. Должен содержать как строчные, так и заглавные буквы/цифры',
        ConfirmPassword: 'Подтвердите пароль',
        ConfirmPwdAgain: 'Подтвердите пароль ещё раз',
        InvitationCode: 'Реферальный код',
        PleaseEnteryourInvitationCode: 'Пожалуйста, введите ваш реферальный код',
        OverAgeAgree: 'Мне больше 18 лет, я прочитал и согласен',
        PleaseAgreeOverAge: 'Сначала отметьте соглашение пользователя',
        RememberYouraccountPassword: 'Запомните ваш пароль аккаунта',
        SelectCountry: 'Язык',

        Reminder: 'Напоминание',
        WantLogOutAccount: 'Вы хотите выйти из своего аккаунта?',
        ConfirmExit: 'Подтвердить выход',
        Cancel: 'Отмена',

        Strength: 'Сила',
        sixteenDigits: '8-16 символов',
        UpperCaseletters: 'Заглавные буквы',
        LowerCaseLetters: 'Строчные буквы',
        Numbers: 'Цифры',

        UNLOCK: 'РАЗБЛОКИРОВАТЬ',
        ENDLESSBENEFITS: 'БЕСКОНЕЧНЫЕ ПРЕИМУЩЕСТВА',
        loginTips1: 'Щедрые бонусы готовы! Присоединяйтесь к нам, найдите свои сокровища',
        loginTips2: 'Эксклюзивное VIP-событие: Делайте ставки и получайте возможности',
        loginTips3: 'Красный конверт, ежедневные регулярные выплаты',
        loginTips4: 'Преимущества приглашения друзей для получения сокровищ.',
        loginTips5: 'Преимущества ежедневного входа',
        loginTips6: 'Когда вы сделаете свой первый депозит, вы можете получить бонус до 20%.',
        loginTips7: 'Обновите VIP, чтобы получить бонусы',
        loginTips8: 'Добро пожаловать в ',
        loginTips9: 'ПРИНЕСИТЕ вам беспрецедентный игровой опыт',
        accountOrPhone: 'Счет / номер телефона'
    },
    logout: {
        SignOut: 'Выйти',
        SignOutTips: 'Исследуйте мир, наполненный наградами и захватывающими играми!'
    },
    signin: {
        signInTitle: 'Ежедневный бонус за вход',
        signInDays: 'Непрерывный вход в течение ',
        days: ' дней',
        day: 'ДЕНЬ',
        claimed: 'Получено ',
        condition1: 'Требуемый депозит',
        condition2: 'Требуемые ставки',
        eventDetails: 'Детали события',
        checkin: 'Регистрация',
        CheckIn: 'ЗАРЕГИСТРИРОВАТЬСЯ',
        Today: 'Сегодня',
        Uncompleted: 'НЕЗАВЕРШЕНО',
        Completed: 'ЗАВЕРШЕНО',
        Conditions: 'Условия',
        Rules: 'Правила'
    },
    share: {
        panel: 'Панель',
        FAQ: 'Часто задаваемые вопросы',
        TUTORIAL: 'УРОК',
        TotalRewards: 'Общие награды',
        TotalFriends: 'Всего друзей',
        inviteFriend: 'ПРИГЛАШАЙТЕ ДРУЗЕЙ, ЧТОБЫ ЗАРАБОТАТЬ',
        signUp: 'Зарегистрируйтесь и зарабатывайте',
        Commission: 'Комиссия',
        InviteVia: 'Пригласить через:',
        copy: 'Копировать',
        link: 'Ссылка',
        code: 'Код',
        or: 'ИЛИ',
        Rewards: 'Награды',
        title1: 'КОМИССИЯ',
        tip1: 'Заработайте до',
        tip2: 'комиссии, когда ваш друг делает ставки.',
        title2: 'КЭШБЭК ЗА ДЕПОЗИТ',
        tip3: 'Заработайте',
        tip4: 'когда ваш друг делает депозит.',
        Details: 'Детали',
        UncollectedCommissions: 'Несобранные комиссии',
        ExclusiveCommission: 'Эксклюзивная комиссия',
        DepositCashback: 'Кэшбэк за депозит',
        Claim: 'Получить',
        TodayTeamData: 'Данные команды за сегодня',
        TotalTeamData: 'Общие данные команды',
        SignUp: 'Регистрация',
        FirstDeposit: 'Первый депозит',
        ValidBet: 'Действительная ставка',
        LiveRewards: 'Живые награды',
        SendTotalCommission: 'Отправить общую комиссию',
        rules: 'Правила',
        ruleInfo: 'Вы можете зарабатывать деньги, приглашая друзей и получая до комиссионных вознаграждений!',
        Recommended: 'Рекомендуется',
        Month: 'Месяц',
        UnlimitedSubordinates: 'Неограниченное развитие подчинённых',
        planTitle: 'УЗНАЙТЕ БОЛЬШЕ О НАШЕЙ ПАРТНЁРСКОЙ ПРОГРАММЕ',
        planInfo: 'Если у вас большая аудитория и подписчики. У нас есть специальные условия для вас, чтобы настроить вашу реферальную программу!',
        all: 'Все',
        Direct: 'Прямые',
        Indirect: 'Косвенные',
        TodayTeamIntro1: 'Зарегистрироваться: Количество пользователей, зарегистрировавшихся сегодня',
        TodayTeamIntro2: 'Первый депозит: Количество пользователей, сделавших первый депозит сегодня',
        TodayTeamIntro3: 'Действительные ставки: Действительные ставки для команды сегодня',
        TotalTeamIntro1: 'Зарегистрироваться: Общее количество регистраций команды',
        TotalTeamIntro2: 'Первый депозит: Общее количество пользователей, сделавших первый депозит',
        TotalTeamIntro3: 'Действительные ставки: Общая сумма действительных ставок команды',
        calculationRules: 'Правила расчёта комиссии',
        rewardRules: 'Правила вознаграждения комиссии',
        ExtendRewardsBouns: 'Расширенные бонусы вознаграждений',
        GameBettingRewardsBouns: 'Бонусы за ставки на игры',
        RechargeRewardsBouns: 'Бонусы за пополнение',
        Tier: 'Уровень',
        intro1: 'Зарегистрироваться: Количество пользователей, зарегистрировавшихся сегодня',
        intro2: 'Первый депозит: Количество пользователей, сделавших первый депозит сегодня',
        intro3: 'Действительные ставки: Действительные ставки для команды сегодня',
        intro4: 'Зарегистрироваться: Общее количество регистраций команды',
        intro5: 'Первый депозит: Общее количество пользователей, сделавших первый депозит',
        intro6: 'Действительные ставки: Общая сумма действительных ставок команды',
        rule1: ' Каждый раз, когда ваш рекомендованный игрок делает ставку, выигрывает или проигрывает, вы получите разный процент комиссии.',

        startTime: 'Начинаем.',
        endTime: 'Конец',
        TotalRewardAmount: 'Общая сумма вознаграждения:',
        // rules: '',
        level: 'Уровень',
        Amount: 'Сумма',
        NumberOfPeople: 'Численность',
        Part1Blogger: 'Награды пользователей',
        FirstRewardAmount: 'Размер вознаграждения за членство первого уровня',
        FirstNumberOfPeople: 'Число действительных членов первого уровня',
        FirstRewardRatio: 'Процентная доля вознаграждения за действительное членство первого уровня',
        FirstPaymentDiff: 'Оплата членства первого уровня',
        FirstRechargeAmount: 'Членский капитал первого уровня',
        FirstWithdrawalAmount: 'Сумма, причитающаяся участникам первого уровня',
        Part2Partners: 'Вознаграждение партнеров',
        PartnerRewardAmount: 'Размер вознаграждения партнеров:',
        PartnerRechargeAmount: 'Сумма пополнения партнеров:',
        PartnerWithdrawalAmount: 'Сумма, предоставленная партнером:',
        FirstEffectiveNumber: 'Число активных партнеров первого уровня',
        SecondNumberOfPeople: 'Число действительных членов второго уровня',
        FirstPartnersRewardRatio: 'Процентная доля премий, выплачиваемых партнерам первого уровня',
        FirstPartnersRewardAmount: 'Размер вознаграждения для партнеров первого уровня',
        FirstPartnersPaymentDiff: 'Разница в оплате партнерами первого уровня',
        SecondEffectiveRechargeAmount: 'Активная сумма членства второго уровня',
        SecondEffectiveWithdrawalAmount: 'Эффективный взнос участника второго уровня',
        SecondRechargeAmount: 'Членский капитал второго уровня',
        SecondWithdrawalAmount: 'Взносы членов второго уровня',
        partnerRulesTips1_1: 'Совершенствование рекомендованного вознаграждения, увеличение доли прибыли рекомендованного лица, блоггер A рекомендует блоггера B, а A получает ',
        partnerRulesTips1_2: ' прибыли B',
        partnerRulesTips2: 'Правила партнёрства: 1000 ставок в неделю, 5000 действительных членов.',
        partnerRulesTips2_1: 'Правила партнёрства оплачиваются ',
        partnerRulesTips2_2: 'один раз в день. ',
        partnerRulesTips2_3: 'раз в неделю. ',
        partnerRulesTips2_4: 'Каждый месяц. ',
        partnerRulesTips2_5: 'При первом пополнении даже действительный член',
        partnerRulesTips2_6: 'Ставка ',
        partnerRulesTips2_7: ', ставка ',
        partnerRulesTips2_8: ' считается действительным членом',
        ReachStandard: 'Число лиц, достигших целевого показателя',
        RewardRatio: 'Процент вознаграждения',
        partner: 'Партнеры'
    },
    deposit: {
        PaymentMethodsRregion: 'Способы оплаты для региона',
        DepositMethods: 'Способы депозита',
        Deposit: 'Депозит',
        FirstDeposit: 'Первый депозит',
        Details: 'Детали',
        Amount: 'Сумма',
        Extra: 'Дополнительно',
        noBonus: 'нет бонуса',
        PleaseEnterDeposit: 'Пожалуйста, введите сумму депозита',
        isMinRechargeAmtTips: 'Сумма пополнения должна быть больше минимальной суммы пополнения',
        isMaxRechargeAmtTips: 'Сумма пополнения должна быть меньше максимальной суммы пополнения',
        showBonusTips: 'Не участвовать в акциях по депозитам',
        rewardPercentageTxt1_1: 'Более',
        rewardPercentageTxt1_2: 'плюс',
        rewardPercentageTxt1_3: '%',
        rewardPercentageTxt2_1: 'Ежедневный первый депозит ',
        rewardPercentageTxt2_2: '+',
        rewardPercentageTxt2_3: '%',
        rewardPercentageTxt3_1: 'Первый депозит при регистрации ',
        rewardPercentageTxt3_2: '+',
        rewardPercentageTxt3_3: '%',
        Change: 'Изменить',
        DepositBonus: 'Бонус за депозит',
        DepositAmount: 'Сумма депозита',
        Bonus: 'Бонус',
        DepositTips: 'Платформенные депозиты предлагают щедрые вознаграждения, при этом за каждую внесённую сумму денег вы получаете бонус.',
        DepositTips2: 'Используйте T - bank или Сбер для пополнения, другие банки пока не поддерживают.',
        howTitle: 'Как получить бонус за депозит?',
        Step: 'Шаг',
        Step2Title: 'Получить бонус за депозит',
        howTips1: 'Перейдите к депозиту. Выберите предпочитаемый способ депозита для внесения депозита.',
        howTips2: 'После завершения депозита вы получите соответствующий бонус в зависимости от суммы депозита и коэффициента бонуса.',
        TransferDeposit: 'ДЕПОЗИТ USDT',
        OnlineDeposit: 'Онлайн депозит',
        ExchangeRate: 'Курс обмена',
        Tutorial: 'Учебное пособие по пополнению USDT',
        TransferAmount: 'Сумма перевода :',
        TargetAccount: 'Целевые счета :',
        showTips: 'После того, как система обнаружит ваш перевод, он увеличит ваш баланс',
        transferred: 'Я уже перевел деньги.',
        information: 'Передача информации',
        name: 'Имя получателя :',
        bank: 'Банки :'
    },
    DepositDetails: {
        DepositDetails: 'Детали депозита',
        WaitingForPayment: 'Ожидание платежа.',
        countDownTxt1: 'Пожалуйста',
        countDownTxt2: 'завершите депозит',
        ExchangeRate: 'Курс обмена',
        DepositMethod: 'Способ депозита',
        Currency: 'Валюта',
        MainnetProtocol: 'Протокол основной сети (канал сети, очень важно)',
        PaymentAddress: 'Адрес платежа',
        CreationTime: 'Время создания',
        OrderNumber: 'Номер заказа',
        TransactionRemark: 'Примечание к транзакции',
        cashAddress: 'адрес для наличных',
        DepositTips1: '1. Пожалуйста, убедитесь, что правильная сумма после вычета комиссии за вывод (правильная полученная сумма) для завершения транзакции; в противном случае депозит не будет успешным.',
        DepositTips2: '2. Подтвердите, что протокол основной сети, который вы переводите/вносите, соответствует выбранному протоколу основной сети. В противном случае депозит/перевод не будет успешным или будет возвращён.',
        DepositTips3: '3. Поддерживается только USDT. Пожалуйста, не переводите другие валюты на этот адрес.',
        cancelBtn: 'отменить заказ',
        TransferredBtn: 'Я перевёл',
        ContinueBtn: 'Продолжить депозит',
        hashAddress: 'Хэш-адрес',
        pleasetEnterHashAddress: 'пожалуйста, введите хэш-адрес',
        cancelOrderTips: 'Вы хотите отменить этот платёжный заказ?',
        waitPayTips: 'В настоящее время есть неоплаченные заказы, хотите ли вы продолжить оплату?'
    },
    Withdraw: {
        Withdraw: 'Вывод',
        BalanceWithdraw: 'Вывод баланса',
        BonusWithdraw: 'Вывод бонуса',
        WithidrawLimitPerday: 'Лимит на вывод в день',
        WithdrawTimePerday: 'Время вывода в день',
        WithdrawableBalance: 'Доступный баланс для вывода',
        WithdrawableBonus: 'Остаток бонуса',
        WithdrawAmount: 'Сумма вывода',
        ratetxt1: 'Мне также нужно сделать ставку',
        ratetxt2: 'чтобы вывести',
        ratetxt3: ' требуют ставки ',
        ratetxt4: ', чтобы снять деньги.',
        ChooseYourBankcard: 'Выберите свою банковскую карту',
        AddABankCardFirst: 'Сначала добавьте банковскую карту',
        WithdrawalAmount: 'Сумма вывода',
        disabledWithdraw: 'Функция вывода текущего аккаунта отключена. Пожалуйста, свяжитесь со службой поддержки!',
        PleaseEnterMiniWithdrawPrice: 'Сумма вывода не может быть меньше минимальной суммы вывода',
        PleaseEnterWithdraw: 'Пожалуйста, введите сумму вывода',
        PleaseEnterInteger: 'Пожалуйста, введите целое число для суммы вывода',
        PleaseChooseBank: 'Пожалуйста, сначала введите информацию о банковской карте',
        VerifyIdentity: 'Подтвердите личность',
        notInTime1: 'Время доступного вывода:',
        notInTime2: 'Время расчёта системы:',
        notInTime3: 'После начала вывода сумма поступит на счёт в течение 24 часов с момента начала вывода. Если у вас есть вопросы, пожалуйста, свяжитесь с нашей службой поддержки.',
        All: 'Все',
        OnlineWithdraw: 'ВЫВОД НА КАРТУ',
        TransferWithdraw: 'USDT Снять деньги',
        USDTPaymentAddress: 'Адрес оплаты USDT',
        PleaseEnterUSDTPaymentAddress: 'Введите адрес оплаты USDT',
        Fee: 'Расходы',
        Receive: 'Прием',
        WithdrawTips1: 'Предложение по выводу средств: Вывод средств невозможен по пятницам, субботам и воскресеньям. Если вы хотите вывести средства в эти три дня, мы рекомендуем вам использовать USDT. Средства USDT поступят на ваш счет в течение десяти минут! (Если средства не поступают на ваш счет в течение десяти минут, пожалуйста, наберитесь терпения, пользователи должны быть рассмотрены по одному, если у вас есть вопросы, пожалуйста, свяжитесь с сотрудниками службы поддержки).',
        WithdrawTips2: 'Советы по выводу средств: При выводе средств мы рекомендуем вам использовать сумму менее 10 000 USDT, чтобы USDT поступили на ваш счет в течение 5 минут. Если вы выводите средства с помощью банковской карты, они будут зачислены на ваш счет в течение 24 часов!'
    },
    bankset: {
        BankCardDetails: 'Детали банковской карты',
        Type: 'Тип',
        AccountName: 'Имя аккаунта',
        NameOfTheAccountHolder: 'Имя владельца аккаунта',
        PIXAccount: 'PIX аккаунт',
        InputPIXAccount: 'Введите PIX аккаунт',
        CPFAccount: 'CPF аккаунт',
        PleaseEnterCPF: 'Пожалуйста, введите CPF',
        WithdrawPassword: 'Пароль для вывода',
        InputWithdrawalPassword: 'Введите пароль для вывода',
        ConfirmWithdrawalPassword: 'Подтвердите пароль для вывода',
        PleaseEnterWithdrawPassword: 'Пожалуйста, введите пароль для вывода',
        InputConfirmWithdrawalPassword: 'Введите подтверждение пароля для вывода',
        ChooseCertificateType: 'Выберите тип сертификата',
        InputAccountName: 'Введите имя аккаунта',
        InputInconsistentWithdrawPassword: 'Несоответствие ввода пароля для вывода',
        PayeeAccount: 'номер счёта получателя',
        BankName: 'Название банка',
        ChooseBank: 'Выберите банк',
        PleaseSelectBank: 'Пожалуйста, выберите банк',
        AccountPhone: 'Номер телефона',
        EnterPhoneNumber: 'введите номер телефона',
        PleaseEnterPhoneNumber: 'Пожалуйста, введите ваш номер телефона',
        email: 'Электронная почта',
        PleaseEnterEmail: 'Пожалуйста, введите ваш адрес электронной почты',
        EmailFormat: 'Неверный формат электронной почты',
        InputPayeeAccount: 'Пожалуйста, введите номер счёта получателя',
        PleaseSelectChannelName: 'Пожалуйста, выберите название канала',
        Save: 'Сохранить',
        PayeeAccountTips1: 'Пожалуйста, введите номер 10.',
        PayeeAccountTips2: 'Введите номер 16 - значной банковской карты.',
        WithdrawTips: 'Введите номер телефона, привязанного к T - банку, чтобы снять деньги, другие банки пока не поддерживают'
    },
    Notification: {
        Notification: 'Уведомление',
        news: 'Новости',
        nomore: 'Больше нет'
    },
    bonusCenter: {
        BonusDetails: 'Детали бонуса',
        BonusCategories: 'Категории бонусов',
        WeeklyBonus: 'Еженедельный бонус',
        MonthlyBonus: 'Ежемесячный бонус',
        LevelupBonus: 'Бонус за повышение уровня',
        BonusRain: 'Красный конверт',
        DepositBonus: 'Бонус за депозит',
        SpecialBonus: 'Специальный бонус',
        BonusTransactions: 'Транзакции бонусов',
        BonusType: 'Тип бонуса',
        ClaimedAmount: 'Полученная сумма',
        Time: 'Время',
        OopsNodatayet: 'Упс! Данных пока нет!',
        TotalBonusClaime: 'Общая сумма полученных бонусов',
        TotalGeneralBonus: 'Активный бонус',
        TotalVIPBonus: 'Общий VIP бонус',
        rechargeBonus: 'Бонус за пополнение',
        Details: 'Детали',
        TotalBonus: 'Общий бонус:',
        Rules: 'Правила',
        Bonusends: 'Бонус заканчивается:',
        DepositNow: 'Пополнить сейчас',
        Generalbonus: 'Обычный бонус',
        Todayevents: 'События сегодня',
        Nextrain: 'Следующий раз',
        CheckIn: 'Зарегистрироваться',
        Counts: 'Количество',
        Deposit: 'Депозит',
        VIPBonus: 'VIP бонус',
        Levelupbonus: 'Бонус за повышение уровня',
        XPtoVIP: 'XP до VIP',
        UpgradeBonus: 'Бонус за повышение',
        NextLevelBonus: 'Бонус следующего уровня',
        ViewVIPClub: 'Посмотреть VIP клуб',
        Wager: 'Ставка',
        VIP: 'VIP',
        Unlockendless: 'Разблокируйте бесконечные преимущества',
        Generousbonuses: 'Щедрые бонусы готовы! Присоединяйтесь к нам. Найдите свои сокровища.',
        JoinNow: 'Присоединяйтесь сейчас',
        Upgradebonuses: 'Обновите VIP, чтобы получить бонусы',
        Invitefriendss: 'Пригласите друзей играть в игры и получите бонусы',
        Completequest: 'Завершите задание, чтобы получить бонусы',
        Winbigwith: 'Выигрывайте крупно с нашими еженедельными и ежемесячными бонусами!',
        Discover: 'Откройте для себя необычные бонусы',
        numerousbonuses: 'Мы предлагаем множество бонусов каждому пользователю. Новые награды каждый день и каждую неделю.',
        Signbonus: 'Зарегистрируйтесь и получите бонус',
        LevelupVIP: 'Повышение уровня VIP, больше наград!',
        VIPstatusmeans: 'Статус VIP означает больше ценности здесь. Вы можете быстро накапливать богатство и создавать прочную финансовую базу, разблокировав его преимущества.',
        Whatget: 'Что получают VIP?',
        Saygoodbye: 'Скажите прощай обычным играм, привет чистой роскоши.',
        RedeemBonusCode: 'ПРИМЕНИТЬ',
        RedeemCode: 'Погасить код',
        AllBonuses: 'Все бонусы',
        eventManage: 'Активный бонус',
        VIPCycleReward: 'Награда за уровень VIP',
        Extend: 'Промо-бонус',
        reliefMoneyEvent: 'Акция по спасению золота',
        singInEvent: 'Акция по регистрации',
        luckyWheelEvent: 'Акция с удачным колесом',
        redPacletEvent: 'Акция с красным конвертом',
        gameBettingEvent: 'Акция по ставкам на игры',
        pinduoduoEvent: 'Пригласительные акции',
        rankingListEvent: 'Рейтинг',
        registerRewardsEvent: 'Акция по регистрации',
        VIPWeeklyReward: 'Еженедельные награды VIP',
        VIPMonthlyReward: 'Ежемесячные награды VIP',
        VIPLevelUpReward: 'Награда за повышение уровня VIP',
        Redeemyour: 'Погасите свой бонусный код ниже.',
        Redeem: 'Погасить',
        Unlocked: 'Разблокировано',
        RechargeAmount: 'Сумма пополнения'
    },
    lossSupport: {
        title: 'КЕШБЕК/CASHBACK',
        lossSupport: 'Поддержка',
        yesterdayLoss: 'Потери вчера',
        todayBonus: 'Бонус поддержки сегодня',
        loseMoney: 'СУММА',
        extraprizes: '% CASHBACK',
        amountDamage: 'СУММА',
        AmountReward: 'CASHBACK',
        EventIntro: 'Инструкции по событию:',
        VIPLevel: 'Класс VIP'
    },
    rebate: {
        title: 'Возврат ставок',
        BettingVolume: 'Объём ставок',
        RewardAmount: 'Сумма вознаграждения',
        EventIntro: 'Инструкции по событию:'
    },
    downApp: {
        downAppSuccTips: 'Вы успешно установили приложение.',
        downAppSuccTips2: 'Вы уже установили приложение. Или нажмите на значок кнопки загрузки в правом верхнем углу адреса браузера, чтобы переустановить.',
        SaveVersionDesktop: 'Сохранить веб-версию на рабочем столе',
        SaveVersionTips: 'Нажмите, чтобы быстро загрузить приложение на рабочий стол',
        QuickVersion: 'Быстрая версия',
        NormalVersion: 'Обычная версия',
        download: 'Скачать',
        NotInDevice: 'Не совместимо с этим устройством',
        Games: 'Игры',
        Apps: 'Приложения',
        Movies: 'Фильмы',
        Books: 'Книги',
        Kids: 'Дети',
        ProductMadness: 'Безумие продуктов',
        InAppPurchases: 'Покупки в приложении',
        reviews: 'отзывы',
        Downloads: 'Загрузки',
        Install: 'Установить',
        Share: 'Поделиться',
        AddToWishlist: 'Добавить в список желаемого',
        appAvailableDevice: 'Это приложение доступно для вашего устройства',
        AboutGame: 'Об этой игре',
        comment1: 'Испытайте азарт карт и волнение высоких ставок в gd-goatbet! Присоединяйтесь к яркому сообществу любителей карт в этой погружающей и социальной карточной игре. Играйте в Teen Patti в различных режимах, участвуйте в захватывающих турнирах и станьте величайшим gd-goatbet. С его аутентичным игровым процессом, социальными функциями и соревновательным духом, gd-goatbet предлагает захватывающий опыт игры в карты, который заставит вас возвращаться снова и снова.',
        comment2: '1. Аутентичный опыт Teen Patti: Погрузитесь в аутентичную и традиционную карточную игру Teen Patti, с реалистичными правилами и увлекательным игровым процессом.',
        comment3: '2. Разнообразные игровые режимы: Исследуйте различные игровые режимы, от классического Teen Patti до инновационных вариаций, предлагая динамичный и развлекательный игровой опыт.',
        comment4: '3. Социальные карточные игры: Связывайтесь с друзьями, присоединяйтесь к клубам и участвуйте в многопользовательских матчах, способствуя социальному и интерактивному игровому окружению.',
        DataSafety: 'Безопасность данных',
        comment5: 'Безопасность начинается с понимания того, как разработчики собирают и делятся вашими данными. Практики конфиденциальности и безопасности данных могут различаться в зависимости от вашего использования, региона и возраста. Разработчик предоставил эту информацию и может обновлять её со временем.',
        comment6: 'Это приложение может делиться этими типами данных с третьими сторонами',
        comment7: 'Местоположение, Личная информация и 6 других',
        comment8: 'Это приложение может собирать эти типы данных',
        comment9: 'Местоположение, Личная информация и 6',
        comment10: 'Данные шифруются в пути',
        comment11: 'Вы можете запросить удаление данных',
        SeeDetails: 'Смотрите детали',
        RatingsAndReviews: 'Рейтинги и отзывы',
        Phone: 'Телефон',
        Tablet: 'Планшет',
        October: 'Октябрь',
        comment12: 'Отличный выбор популярных игр, которые также есть в казино, и много удовольствия от игры! Просмотр рекламы может поддерживать игру, но иногда это может быть мучительно медленно. Будьте готовы делать покупки или иметь много терпения. Иногда вам нужно смотреть рекламу от 30 секунд до минуты между несколькими раздачами, и это может продолжаться несколько раздач. В некоторых играх вы не можете выполнить требования бонуса, если не купите деньги для игры. ВСЕ-TAK, это моя любимая из всех казино-приложений!',
        comment13: '3,394 человека нашли этот отзыв полезным',
        comment14: 'Вы нашли это полезным?',
        Yes: 'Да',
        No: 'Нет',
        September: 'Сентябрь',
        comment15: 'Мне действительно нравится эта игра. У неё есть разнообразие различных слотов для игры. Я могу зарабатывать дополнительные монеты по мере игры. Не так много рекламы для просмотра. Сумма монет при покупке ниже, чем в других играх, но из-за качества этой игры они стоят того, на мой взгляд. Я скачал и затем удалил другие приложения после их использования.',
        comment16: '1,110 человек нашли этот отзыв полезным',
        comment17: 'Вы нашли это полезным?',
        WhatNew: 'Что нового',
        comment18: 'Получите наши НОВЫЕ игры в нашем последнем обновлении!',
        comment19: 'Для лучшего опыта и самого БОЛЬШОГО УДОВОЛЬСТВИЯ мы исправили ошибки и оптимизировали вашу игру.',
        AppSupport: 'Поддержка приложения',
        SimilarGames: 'Похожие игры',
        LightningLinkCasinoSlots: 'Слоты казино Lightning Link',
        QuickHitCasinoSlotGames: 'Слоты казино Quick Hit',
        SciPlay: 'SciPlay',
        LuckyTimeSlots: 'Слоты Lucky Time',
        DGNGames: 'DGN Games',
        CashTornadoSlotsCasino: 'Слоты Cash Tornado™ - Казино',
        ZerooGravityGames: 'Игры Zeroo Gravity',
        GoldFishCasinoSlot: 'Слот Gold Fish Casino',
        FlagInappropriate: 'Отметить как неподобающее',
        GooglePlay: 'Играть',
        PlayPass: 'Играть',
        PlayPoints: 'Игровые очки',
        GiftCards: 'Подарочные карты',
        Redeem: 'Погасить',
        RefundPolicy: 'Политика возврата',
        KidsFamily: 'Дети и семья',
        ParentGuide: 'Руководство для родителей',
        FamilySharing: 'Семейный доступ',
        TermsService: 'Условия обслуживания',
        Privacidade: 'Конфиденциальность',
        SobreGooglePlay: 'О Play',
        Desenvolvedores: 'Разработчики',
        GoogleStore: 'Магазин',
        UnitedStates: 'Соединённые Штаты (английский)',

        AppStore: 'App Store',
        DesignedIPhone: 'Разработано для iPhone',
        get: 'получить',
        iPhoneScreenshots: 'Скриншоты iPhone',
        AppPrivacy: 'Конфиденциальность приложения',
        intro1: 'Разработчик, ',
        intro2: ', указал, что практики конфиденциальности приложения могут включать обработку данных, как описано ниже. Для получения дополнительной информации смотрите ',
        intro3: 'политику конфиденциальности разработчика',
        intro4: 'Данные не собираются',
        intro5: 'Разработчик не собирает никаких данных из этого приложения.',
        intro6: 'Практики конфиденциальности могут различаться, например, в зависимости от функций, которые вы используете, или вашего возраста.',
        intro7: 'Информация',
        Seller: 'Продавец',
        Size: 'Размер',
        Category: 'Категория',
        Compatibility: 'Совместимость',
        iPhone: 'iPhone',
        support1: 'Требуется iOS 10.0 или новее.',
        iPodTouch: 'iPod touch',
        support2: 'Требуется iOS 10.0 или новее.',
        Mac: 'Mac',
        support3: 'Требуется macOS 11.0 или новее и Mac с чипом Apple M1 или новее.',
        Languages: 'Языки',
        EnglishSimplifiedChinese: 'Английский, Упрощённый китайский',
        AgeRating: 'Возрастной рейтинг',
        UnrestrictedWebAccess: 'Неограниченный доступ в интернет',
        InfrequentMildHorrorFearThemes: 'Редкие/умеренные темы ужаса/страха',
        Copyright: 'Авторское право',
        Price: 'Цена',
        Free: 'Бесплатно',
        PrivacyPolicy: 'Политика конфиденциальности',
        Supports: 'Поддержка',
        GameCenter: 'Центр игр',
        GameCenterTips: 'Соревнуйтесь с друзьями и проверяйте таблицы лидеров и достижения.',
        Morewaysshop: 'Больше способов покупки',
        FindAppleStore: 'Найдите магазин Apple',
        or: 'или',
        otherRetailer: 'другой ритейлер',
        nearYou: 'рядом с вами.',
        OrCall: 'Или позвоните по номеру 1-800-MY-APPLE.',
        ChooseYourCountryRegion: 'Выберите вашу страну или регион',
        TermsUse: 'Условия использования',
        SalesRefunds: 'Продажа и возврат',
        Legal: 'Юридическая информация',
        SiteMap: 'Карта сайта',
        ClickButtonToDownload: 'Скачайте приложение для более безопасных выводов',
        ClickButtonToDownload2: 'Скачайте приложение, чтобы играть в игры'
    },
    event: {
        Detail: 'Детали',
        greatBonus: 'Отличный бонус за ',
        everyDeposit: 'Каждый депозит',
        firstDeposit: 'Первый депозит',
        secondDeposit: 'Второй депозит',
        thirdDeposit: 'Третий депозит',
        upTo: 'До',
        allPlayers: 'Доступно для всех игроков ',
        before: 'Перед',
        Bonus: 'Бонус',
        Deposit: 'Депозит'
    },
    person: {
        Bets: 'Ставки',
        Balance: 'Баланс',
        Bonus: 'Бонус',
        Transaction: 'Транзакция',
        History: 'История',
        Welcome: 'Добро пожаловать в ',
        signIn: 'Пожалуйста, сначала зарегистрируйтесь или войдите',
        invite: 'Пригласить друзей',
        redPacket: 'Красный конверт',
        Notification: 'Уведомление',
        Promotion: 'БОНУСЫ',
        VIPClub: 'VIP клуб',
        LiveChat: 'Живой чат',
        AccountSettings: 'Настройки аккаунта',
        Install: 'Установить',
        BonusTransfer: 'Перевод премий',
        transferMsg: 'Бонусов недостаточно.',
        transferTips1: 'Вы хотите перевести ',
        transferTips2: ' долларов в остаток?',
        transferTips3: 'Немедленный перевод'
    },
    Vip: {
        VIPSupport: 'Поддержка VIP',
        LevelBenefits: 'Уровень VIP и преимущества',
        Deposits: 'Депозиты',
        Bets: 'Ставки',
        or: 'или',
        to: 'к',
        joinClub: 'Присоединяйтесь к VIP клубу. Разблокируйте высокие кэшбэки. Все виды изысканных подарков. Это вас удивит!',
        levelUp: 'Повышение уровня VIP',
        Steps: 'Шаги',
        Benefits: 'Преимущества',
        benefits1: 'Бонус за повышение уровня',
        benefits2: 'Еженедельный бонус',
        benefits3: 'Ежемесячный бонус',
        info1: 'Повышение уровня VIP для получения бонуса!',
        info2: 'Получайте бесплатный бонус каждую неделю.',
        info3: 'Получайте бесплатный бонус каждый месяц.',
        More: 'Больше',
        promotionTip: 'Пожалуйста, смотрите наши акции для получения дополнительной информации.',
        Promotions: 'Акции',
        FAQ: 'Часто задаваемые вопросы',
        question1: '1. Что такое "Бонус за повышение уровня"?',
        answer1: ' "Бонус за повышение уровня" - это привилегия VIP, где вознаграждения увеличиваются по мере продвижения к более высоким уровням VIP.',
        question2: '2. Преимущества повышения уровня VIP',
        answer2: 'Повышение уровня VIP может дать более высокую сумму вывода и более низкую комиссию за вывод!',
        LevelUpBonus: 'Бонус за повышение уровня',
        WeeklyBonus: 'Еженедельный бонус',
        MonthlyBonus: 'Ежемесячный бонус',
        Level: 'Уровень',
        RequiredDeposit: 'Требуемый депозит',
        RequiredBet: 'Требуемая ставка',
        Get: 'Получить',
        UpgradeBonus: 'Бонус за повышение',
        vipRewards: 'Зарегистрируйтесь и получите эксклюзивные награды VIP!',
        earnPoints: 'Делайте ставки и зарабатывайте очки!',
        levelUpBenifits: 'Разблокируйте награды, повышайте свои преимущества!',
        Step1: 'Шаг1',
        Step2: 'Шаг2',
        Step3: 'Шаг3'
    },
    MyProfile: {
        Profile: 'Профиль',
        ID: 'ID',
        Join: 'Присоединиться',
        TotalBet: 'Общая ставка',
        TotalBonus: 'Общий бонус',
        FavoriteGames: 'Избранные игры'
    },
    Transaction: {
        Transaction: 'Транзакция',
        TotalAmount: 'Общая сумма',
        DepositValue: 'Сумма депозита',
        Bonus: 'Бонус',
        Received: 'Получено',
        WithdrawBonus: 'Вывод бонуса',
        Rate: 'комиссия',
        Today: 'Сегодня',
        Yesterday: 'Вчера',
        LastThreeDays: 'Последние 3 дня',
        LastSevenDays: 'Последние 7 дней',
        LastFifteenDays: 'Последние 15 дней',
        LastThiryDays: 'Последние 30 дней',
        AllState: 'Все состояния',
        Processing: 'Обработка',
        Successful: 'Успешно',
        Failed: 'Неудачно',
        WithdrawFail: 'Неудача при выводе',
        Withdrawing: 'Вывод средств',
        WithdrawSuccess: 'Вывод средств успешен',
        PaySuccess: 'Оплата успешна',
        Paying: 'Оплата',
        Fail: 'Неудача',
        WaitPay: 'Ожидание оплаты',
        Cancel: 'Отмена'
    },
    BetsHistory: {
        BetsHistory: 'История ставок'
    },
    Setting: {
        Setting: 'Настройки',
        LiveChat: 'Живой чат',
        AccountID: 'ID аккаунта',
        PhoneNumber: 'Номер телефона',
        Password: 'Пароль',
        Change: 'Изменить',
        oldPasswordTips: 'введите оригинальный пароль',
        PleaseEnterYourOriginalPassword: 'Пожалуйста, введите ваш оригинальный пароль',
        passwordTips: 'Пароль (6-16)',
        PleaseEnterYourNewPassword: 'Пожалуйста, введите ваш новый пароль',
        passwordTips2: 'Введите пароль еще раз',
        PleaseEnterYourNewPasswordSure: 'Пожалуйста, введите ваш новый пароль еще раз',
        changePwd: 'Сменить пароль'
    },
    Wallet: {
        Wallet: 'Кошелек',
        TotalBalance: 'Общий баланс',
        Cash: 'Наличные',
        safeFunds: 'Средства очень безопасны',
        tip1: 'это официальная онлайн-гемблинг платформа.',
        tip2: 'Мы принимаем государственный контроль, поэтому у нас более высокая надежность и лучшая безопасность.',
        tip3: 'Пожалуйста, делайте ставки с уверенностью, ваши средства будут гарантированы на самом высоком уровне от начала до конца.'
    },
    RedPacket: {
        RedPacket: 'Красный пакет',
        NextEvent: 'Следующее событие',
        Participate: 'Участвовать'
    },
    turntable: {
        lottery: 'Лотерея',
        title: 'Колесо удачи',
        totalTips1: 'Текущие счастливые очки ',
        totalTips2: 'Вам еще нужно сделать ставку ',
        totalTips3: ' чтобы получить ',
        totalTips4: ' Счастливые очки',
        totalTips5: 'Первая зарядка в день дает ',
        totalTips6: ' счастливых значений.',
        Silver: 'Серебро',
        Gold: 'Золото',
        Diamond: 'Алмаз',
        tab1: 'Отчет',
        tab2: 'Моя ссылка',
        JustRemoved: 'только что убрано',
        drawTitle1: 'Поздравляем',
        drawTitle2: 'к сожалению',
        drawContent1: 'получить',
        drawContent2: 'Награда не получена',
        cashOut: 'Вывод средств',
        InviteFriends: 'Пригласите друзей, чтобы получить деньги',
        nextFreeSpin: 'Следующий бесплатный спин:',
        targetAmount: 'Целевая сумма:',
        withdrawSuccessfully: 'Вывод успешен',
        downTimeTips1: 'Через',
        downTimeTips2: 'секунд,',
        downTimeTips3: 'накопленные бонусы и лотерейные разы будут очищены'
    },
    winRankList: {
        RankingList: 'Рейтинг',
        Ranking: 'Рейтинг',
        Rules: 'Правила',
        MyRewards: 'Мои награды',
        ActivityAward: 'Награда за активность:',
        Rank: 'Ранг',
        Daily: 'Ежедневно',
        Weekly: 'Еженедельно',
        Monthly: 'Ежемесячно',
        WeeklyList: 'Еженедельный список',
        MonthlyList: 'Ежемесячный список',
        TotalBets: 'Общие ставки',
        Reward: 'Награда:',
        NoRank: 'Нет ранга',
        MyBets: 'Мои ставки',
        RanksLeft: 'Осталось рангов',
        MyReward: 'Моя награда',
        History: 'История',
        LastDay: 'Последний день',
        LastWeek: 'Последняя неделя',
        LastMonth: 'Последний месяц',
        Rewards: 'Награды',
        PhoneNo: 'Телефон'
    },
    footer: {
        Promo: 'Промо',
        VIPClub: 'VIP клуб',
        Promotions: 'Акции',
        ReferAndEarn: 'Пригласите и зарабатывайте',
        Bonus: 'Бонус',
        AboutUs: 'О нас',
        About: 'О',
        DataProtectionPolicy: 'Политика защиты данных',
        BusinessContinuityPolicy: 'Политика непрерывности бизнеса',
        Help: 'Помощь',
        PrivacyPolicy: 'Политика конфиденциальности',
        ServiceTerms: 'Условия обслуживания',
        GamblingAddictionWarnings: 'Предупреждения о зависимости от азартных игр',
        GamblingTreatmentCenters: 'Центры лечения азартных игр',
        ResponsibleGambling: 'Ответственная игра',
        ContactUs: 'Связаться с нами',
        JoinOurGroup: 'Присоединяйтесь к нашей группе',
        ContactViaTelegram: 'Связаться через Telegram',
        OnlineServer: 'Онлайн-сервер',
        JoinourCommunity: 'Присоединяйтесь к нашему сообществу',
        tips1: '1. Этот продукт предназначен для пользователей старше 18 лет и предназначен только для развлекательных целей.',
        tips2: '2. Эта игра содержит покупки внутри приложения.',
        tips3: '3. То, что игрок играет или выигрывает в социальной азартной игре, не означает, что он выиграет на ставках на реальные деньги и связанных играх в будущем.'
    },
    treasureChest: {
        title: 'ПРИГЛАШАЙТЕ ДРУЗЕЙ, ЧТОБЫ ОТКРЫТЬ СУНДУК С СОКРОВИЩАМИ',
        save: 'Нажмите, чтобы сохранить',
        ReferralLink: 'Реферальная ссылка',
        directSubordinates: 'Прямые подчиненные',
        ReferralCode: 'Реферальный код',
        chestInfoTips1: 'ЧТО ТАКОЙ АКТИВНЫЙ РЕФЕРАЛ? (СООТВЕТСТВУЕТ ВСЕМ ТРЕБОВАНИЯМ)',
        chestInfoTips2: 'РЕФЕРАЛ СДЕЛАЛ ДЕПОЗИТОВ В ОБЩЕМ НА ',
        chestInfoTips3: '',
        chestInfoTips4: 'РЕФЕРАЛЫ СОВЕРШИЛИ СТАВКИ НА ',
        chestInfoTips5: ' И БОЛЕЕ',
        People: 'РЕФЕРАЛ',
        People2: 'РЕФЕРАЛА',
        People3: 'РЕФЕРАЛОВ',
        commissionAccount: 'Комиссионный аккаунт',
        todayCommittee: 'Комиссия, выпущенная сегодня',
        historyCommittee: 'Опубликованные исторические комиссии',
        obtain: 'получить',
        inviteTitle: 'Получено за приглашение действительных подчиненных',
        highestRebate: 'Максимальный возврат',
        commissionBalance: 'Баланс комиссии',
        totalCommissionIncome: 'Общий доход от комиссии',
        EstimatedCommissionToday: 'Ожидаемая комиссия сегодня',
        YesterdayCommittee: 'Комиссия за вчера',
        register: 'Зарегистрироваться',
        firstChargeMark: 'Знак первого пополнения',
        firstFee: 'Первая плата',
        chargeAmount: 'Сумма пополнения',
        totalHistoricalData: 'Общие исторические данные',
        firstLevelSubordinateData: 'Данные подчиненных первого уровня',
        secondarySubordinateData: 'Данные подчиненных второго уровня',
        thirdLevelSubordinateData: 'Данные подчиненных третьего уровня',
        rewardRatio: 'Коэффициент вознаграждения',
        collectRebates: 'Сбор вознаграждений за пополнение',
        teamCollection: 'Пополнение',
        firstLevelCommission: 'Комиссия первого уровня:',
        secondLevelCommission: 'Комиссия второго уровня:',
        thirdLevelCommission: 'Комиссия третьего уровня:',
        firstLevelBetting: 'Ставки первого уровня:',
        secondLevelBetting: 'Ставки второго уровня:',
        thirdLevelBetting: 'Ставки третьего уровня:'
    },
    Pending: {
        Pending: 'В ожидании',
        Hour: 'Час',
        Type: 'Тип',
        Bonus: 'Бонус',
        Limit: 'Лимит',
        EndTime: 'Время окончания',
        All: 'Все',
        DailyAttendance: 'Ежедневное присутствие',
        TimedRedEnvelope: 'Временный красный конверт',
        LuckyTurntable: 'Счастливое колесо',
        ReliefMoney: 'Деньги помощи',
        RegisterRewards: 'Награды за регистрацию',
        Other: 'Другое'
    },
    depositTotal: {
        title: 'Накопленное вознаграждение за пополнение',
        AccumulatedRecharge: 'Совокупное пополнение',
        GetRewards: 'Награды',
        EventIntro: 'Описание деятельности：'
    },
    depositRepeat: {
        full: 'Полное',
        RegisterFirstCharge: 'Регистрация первого залога',
        DailyFirstCharge: 'Первая зарядка в день',
        EachRecharge: 'Каждое пополнение',
        give: 'Подарок',
        thRecharge: ' - е пополнение',
        EveryRecharge: 'Каждое пополнение',
        EventIntro: 'Описание деятельности：'
    }
}
